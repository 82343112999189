const data = [
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/instituteofliverandbillarysciences/1.png",
    text: "1",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/instituteofliverandbillarysciences/2.png",
    text: "2",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/instituteofliverandbillarysciences/3.png",
    text: "3",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/instituteofliverandbillarysciences/4.png",
    text: "4",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/instituteofliverandbillarysciences/5.png",
    text: "5",
  },
];

export default data;
