import React, { Component } from "react";
import "./style.scss";
import Header from "../../header";
import Layout from "../../layout";
import ProjectLayout from "../../projectlayout2";
import sliderImages from "./slider.data";
import SEO from "../../seo";

const details = {
  title: "Institute of Liver and Billary Sciences, New Delhi",
  address: "Vasant Kunj, New Delhi",
  client: "Institute of Liver and Billary Sciences, Vasant Kunj",
  area: "4.0 Lacs Sq. Ft.",
  project:
    "Residential Complex for Institute of Liver and Biliary Sciences, Vasant Kunj",
  category: "Residential | Architecture | Institute",
  status: "Design Competition",
  backlink: "/projects/residential/",
};

export default class Projectinstituteofliverandbillarysciencesnewdelhi extends Component {
  render() {
    return (
      <Layout>
        <SEO
          title="A&D Studio | Institute of Liver and Billary Sciences, New Delhi"
          img="https://archdesignsstudio.com/assets/linkpreview.png"
          description="View the site plan and design entry for the architecture of a residential complex for a leading hospital in New Delhi. "
        />
        <Header isWhite />
        <ProjectLayout details={details} projectimages={sliderImages} />
      </Layout>
    );
  }
}
